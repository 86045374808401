import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { Layout } from "@components/Layout"
import { Banner, mapToPageHeader } from "@components/Banner"
import { SEO, mapToSEO } from '@components/SEO'
import { SignupBannerWrapper, mapToKiwiVIPVisible } from "@components/SignupBanner";
import { SimpleCombinationFinder } from "@components/CombinationFinder"
import { Grid, Responsive } from "semantic-ui-react"
import { PlateDesignCard } from "@components/PlateDesignCard"
import { mapToPlateDesignList } from "@mappers/ProductModels/mapper"
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import { VehicleTypesSetting, VehicleType } from "@models/ProductModels"
import { DefaultCombination } from "@utils/Constant"
import { getDefaultCombination } from "@utils/Helpers"
import { PlateDesignCardColumn } from "@components/PlateDesignCard/PlateDesignCard.styles"
import theme from "@styles/theme"

interface Props {
  data: any;
  location: string
}
interface PageState {
  combination: string
  vehicleType: VehicleType
  isVehicleTypeChanged: boolean
}
class PlateDesignLandingPage extends React.Component<Props, PageState> {

  constructor(props: Props) {
    super(props);
    this.state = {
      combination: DefaultCombination.Car,
      vehicleType: VehicleTypesSetting.Car,
      isVehicleTypeChanged: false
    }
    this.resetVehicleType = this.resetVehicleType.bind(this)
  }

  resetVehicleType = () => {
    this.setState({
      isVehicleTypeChanged: false
    })
  }

  getVehicleTypeById = (vt: number) => {
    return VehicleTypesSetting.Motorbike.id === vt ?
      VehicleTypesSetting.Motorbike : (
        VehicleTypesSetting.Trailer.id === vt ?
          VehicleTypesSetting.Trailer :
          VehicleTypesSetting.Car
      );
  }

  handleCombinationUpdate = (combination: string) => {
    this.setState({ combination: combination });
  }
  handleVehicleTypeChange = (vt: number) => {
    const vehicleType = this.getVehicleTypeById(vt);
    const defaultCombination = getDefaultCombination(vehicleType)
    if (vehicleType.id != this.state.vehicleType.id) {
      this.setState({ isVehicleTypeChanged: true })
    } else {
      this.setState({ isVehicleTypeChanged: false })
    }
    this.setState({ vehicleType: vehicleType, combination: defaultCombination });
  }
  render() {
    const { elements } = this.props.data.kontentItemAbLandingPage.elements.plate_design.linked_items[0];
    const apiUrl = this.props.data.site.siteMetadata.apiUrl;
    const { combination, vehicleType } = this.state;
    const seo = mapToSEO(elements);
    const kiwiVip = mapToKiwiVIPVisible(elements);
    const metadata = mapToPageHeader(elements);
    const plateDesignList = mapToPlateDesignList(elements.plate_designs.linked_items, this.state.combination, vehicleType);
    const reCaptchaSiteKey = this.props.data.site.siteMetadata.reCaptchaSiteKey;
    const reCaptchaSiteKeyV2 = this.props.data.site.siteMetadata.reCaptchaSiteKeyV2;

    return (<Layout location={this.props.location}>
      <SEO {...seo} />
      <Banner {...metadata} isBiggerBanner={false} />      
      <GenericBannerContainer
        padding={{
          mobile: {
            top: 0,
            bottom: 70
          },
          desktop: {
            top: 0,
            bottom: 90
          }
        }}
        backgroundColor='#F9F9F9'>
        <ResponsiveGrid>
          <SimpleCombinationFinder apiUrl={apiUrl} handleCombinationUpdate={this.handleCombinationUpdate} handleVehicleTypeChange={this.handleVehicleTypeChange} 
      vehicleType={vehicleType} carOnly={false} reCaptchaSiteKey={reCaptchaSiteKey} reCaptchaSiteKeyV2={reCaptchaSiteKeyV2} darkTheme={false} />
          {
            plateDesignList.map((list, key) => {
              const columnKey = list.metaInfo.name + ' ' + vehicleType.name;
              return (
                <PlateDesignCardColumn key={columnKey}>
                  <PlateDesignCard apiUrl={apiUrl} cardKey={key} vehicleType={vehicleType} showMoreDetailsButton={true} metaInfo={list.metaInfo}
                    formattedCombination={combination} productCategoryList={list.products} selected={false} />
                </PlateDesignCardColumn>
              )
            })
          }
        </ResponsiveGrid>
      </GenericBannerContainer>

      {
        kiwiVip.visible &&
        <SignupBannerWrapper version={kiwiVip.version} />
      }
    </Layout>);
  }
}

const ResponsiveGrid = ({ children }: any) => {
  return (
    <Fragment>
      <Responsive maxWidth={theme.responsivePlateDesign.mobile.maxWidth} as={Grid} columns={1}>
        {children}
      </Responsive>
      <Responsive minWidth={theme.responsivePlateDesign.tablet.minWidth} maxWidth={theme.responsivePlateDesign.tablet.maxWidth} as={Grid} columns={2}>
        {children}
      </Responsive>
      <Responsive minWidth={theme.responsivePlateDesign.desktop.minWidth} maxWidth={theme.responsivePlateDesign.desktop.maxWidth} as={Grid} columns={3}>
        {children}
      </Responsive>
      <Responsive minWidth={theme.responsivePlateDesign.widescreen.minWidth} as={Grid} columns={4}>
        {children}
      </Responsive>
    </Fragment>
  )
}

export const query = graphql`{
  site {
    siteMetadata {
      apiUrl
      reCaptchaSiteKey
      reCaptchaSiteKeyV2
    }
  }
  kontentItemAbLandingPage {
    elements {
      plate_design {
        linked_items {
          ... on KontentItemPlateDesignLandingPage {
            elements {
              seo__noindex {
                value {
                  name
                  codename
                }
              }
              seo__nofollow {
                value {
                  name
                  codename
                }
              }
              seo__page_title {
                value
              }
              seo__page_description {
                value
              }
              general_page_content__title {
                value
              }
              general_page_content__header_image {
                value {
                  url
                  description
                }
              }
              general_page_content__responsive_header_image {
                linked_items {
                  ... on KontentItemBannerImage {
                    id
                    elements {
                      desktop {
                        value {
                          url
                          description
                        }
                      }
                      mobile {
                        value {
                          description
                          url
                        }
                      }
                      tablet {
                        value {
                          description
                          url
                        }
                      }
                    }
                  }
                }
              }
              general_page_content__kiwivip_signup {
                value {
                  name
                  codename
                }
              }
              general_page_content__kiwivip_signup_version {
                value {
                  name
                  codename
                }
              }
              general_page_content__title_position {
                value {
                  codename
                }
              }
              url {
                value
              }
              general_page_content__responsive_header_image {
                linked_items {
                  ... on KontentItemBannerImage {
                    id
                    elements {
                      desktop {
                        value {
                          url
                          description
                        }
                      }
                      mobile {
                        value {
                          description
                          url
                        }
                      }
                      tablet {
                        value {
                          description
                          url
                        }
                      }
                    }
                  }
                }
              }
              plate_designs {
                linked_items {
                  ... on KontentItemProductCategory {
                    elements {
                      category_name {
                        value
                      }
                      url {
                        value
                      }
                      category_description_rich {
                        value
                      }
                      new_design {
                        value {
                          codename
                          name
                        }
                      }
                      vehicle_types {
                        value {
                          name
                          codename
                        }
                      }
                      fixed_fomat_price {
                        value
                      }
                      flexi_format_price {
                        value
                      }
                      upgrade_starting_price {
                        value
                      }
                      redesign_starting_price {
                        value
                      }
                      premium_3_character_price {
                        value
                      }
                      premium_2_character_price {
                        value
                      }
                      product_designs {
                        linked_items {
                          ... on KontentItemPlateDesign {
                            elements {
                              name {
                                value
                              }
                              dealer_only_design {
                                value {
                                  name
                                  codename
                                }
                              }
                              disable_dual_sizing {
                                value {
                                  codename
                                  name
                                }
                              }
                              authorized_dealers {
                                linked_items {
                                  ... on KontentItemAuthorizedDealers {
                                    elements {
                                      title {
                                        value
                                      }
                                      link_message {
                                        value
                                      }
                                      description {
                                        value
                                      }
                                      dealer_contacts {
                                        linked_items {
                                          ... on KontentItemDealerContact {
                                            elements {
                                              dealer_name {
                                                value
                                              }
                                              contact_name {
                                                value
                                              }
                                              phone {
                                                value
                                              }
                                              formatted_phone_number {
                                                value
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              transaction_types {
                                value {
                                  name
                                  codename
                                }
                              }
                              vehicle_types {
                                value {
                                  name
                                  codename
                                }
                              }
                              kpi_plate_design_code {
                                value {
                                  name
                                  codename
                                }
                              }
                              kpi_plate_foreground_color {
                                value {
                                  name
                                  codename
                                }
                              }
                              plate_size {
                                value {
                                  name
                                  codename
                                }
                              }
                              plate_color {
                                value {
                                  name
                                  codename
                                }
                              }
                              base_price {
                                value
                              }
                              redesign_price {
                                value
                              }
                              govt_upgrade_price {
                                value
                              }
                              captions {
                                value {
                                  name
                                  codename
                                }
                              }
                              max_message_length__mm_ {
                                value
                              }
                              bottom_caption_max_length__mm_ {
                                value
                              }
                              message_colors {
                                value {
                                  name
                                  codename
                                }
                              }
                              european_brand_name {
                                value {
                                  name
                                  codename
                                }
                              }
                              dual_size_plate {
                                linked_items {
                                  ... on KontentItemPlateDesign {
                                    elements {
                                      name {
                                        value
                                      }
                                      dealer_only_design {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      disable_dual_sizing {
                                        value {
                                          codename
                                          name
                                        }
                                      }
                                      authorized_dealers {
                                        linked_items {
                                          ... on KontentItemAuthorizedDealers {
                                            elements {
                                              title {
                                                value
                                              }
                                              link_message {
                                                value
                                              }
                                              description {
                                                value
                                              }
                                              dealer_contacts {
                                                linked_items {
                                                  ... on KontentItemDealerContact {
                                                    elements {
                                                      dealer_name {
                                                        value
                                                      }
                                                      contact_name {
                                                        value
                                                      }
                                                      phone {
                                                        value
                                                      }
                                                      formatted_phone_number {
                                                        value
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                      transaction_types {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      vehicle_types {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      kpi_plate_design_code {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      kpi_plate_foreground_color {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      plate_size {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      plate_color {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      base_price {
                                        value
                                      }
                                      redesign_price {
                                        value
                                      }
                                      govt_upgrade_price {
                                        value
                                      }
                                      captions {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      max_message_length__mm_ {
                                        value
                                      }
                                      bottom_caption_max_length__mm_ {
                                        value
                                      }
                                      message_colors {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                      european_brand_name {
                                        value {
                                          name
                                          codename
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export default PlateDesignLandingPage;